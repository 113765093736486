import { useRef, useEffect } from 'react';
import { shuffle }from 'txt-shuffle';
import audioSrc from "../assets/morse-1.wav";

const ScrambleText = ({ enter, hasPermission }) => {
   const charRef = useRef(null);
   const audioRef = useRef(new Audio(audioSrc));
   const { duration } = audioRef.current;
   const targetString = "learning";
   const originalString = ".-.. . .- .-. -. .. -. --";
   let isPlaying = false;

   
   useEffect(() => {
      if (enter || hasPermission) {
    
            shuffle({ 
               text: targetString, 
               fps: 24, 
               direction: 'random',
               glyphs: originalString,
               duration: duration, 
               onUpdate: (output) => {
                  if (isPlaying) {
                     charRef.current.innerText = output
                  } else {
                     audioRef.current.play();
                     isPlaying = true
                  }
               },
               onComplete: () => {
                  audioRef.current.pause();
               }
            })
         
       }
     },[enter, hasPermission])

  return <p ref={charRef}></p>;
};

export default ScrambleText;
